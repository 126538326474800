import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const AlertsForSite = (prop) => {
  const [collapsible, setCollapsible] = React.useState(true);

  React.useEffect(() => {
    console.log(
      "AlertsForSite func: ",
      prop,
      "collapsible: ",
      collapsible,
      "prop.children: ",
      prop.children,
    );
    if (!collapsible) {
      prop.item();
    }
  }, [collapsible]);

  return (
    <Collapse
      bordered={false}
      // defaultActiveKey={['1']}
      // activeKey={collapsible ? "1" : "2"}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className="site-collapse-custom-collapse"
      style={{
        border: "2px solid navyblue",
        background: prop.red ? "#6C0F02" : "#1f3161",
        borderRadius: 3,
        color: "white",
        alightItems: "center",
        marginRight: 3,
        fontWeight: 600,
        fontSize: 12,
        borderBottomColor: "red",
        borderBottomWidth: "4px",
      }}
      // style={{  border: '1px solid navyblue', background: '#2E4FA5', borderRadius: 3,  color:"white",  alightItems: 'center', marginRight: 3 }}
      onChange={() => setCollapsible(!collapsible)}
      expandIconPosition="end"
      accordion={false}
    >
      <Panel
        // header={` ${prop.site_name} \n`}
        header={
          <div style={{ height: 40, paddingBottom: 15 }}>
            <p>{prop.site_name}</p>
            <div
              style={{
                paddingBottom: 20,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: 10,
              }}
            >
              <p>
                <i>Total: </i> {prop.count}
              </p>{" "}
              <p style={{ marginLeft: 10 }}>
                {" "}
                <i>Time: </i> {prop.time}
              </p>
            </div>
          </div>
        }
        // onClick={() => setCollapsible(!collapsible)}
      >
        {!collapsible && <>{prop.children}</>}
      </Panel>
    </Collapse>
  );
};

export default AlertsForSite;
