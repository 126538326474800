import React from "react";
import { useStyles, CarIcon, PeopleIcon } from "../../styling/global/global";
import { Alert } from "../../../services/FunctionService";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import AssistantPhotoIcon from "@material-ui/icons/AssistantPhoto";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { DirectionsCar, DirectionsWalk, Pets, Help } from "@material-ui/icons";
import { AppStore } from "../../../stores/AppStore";
import SecurityIcon from "@material-ui/icons/Security";
import SportsIcon from "@material-ui/icons/Sports";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import WavesIcon from "@material-ui/icons/Waves";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AlertsForSite from "../AlertsForSite/AlertsForSite";

interface RightDrawerProps {
  appStore?: AppStore;
  alerts: Alert[];
  escalatedalerts: Alert[];
  events: [{ [key: string]: any }];
  setSelectedAlert: (alert: Alert | undefined) => void;
  loadingright: boolean;
  alert: Alert | undefined;
  showhistory: boolean;
  showbulk: boolean;
  selectedalerts: string[];
  setSelectedAlerts: (selectedalers: string[]) => void;
  setAlertsForSite: (selectedalers: any[]) => void;
  setShowBulk: (show: boolean) => void;
  currenttheme: string;
  showalerts: boolean;
  showhistoryrole: boolean;
  showescalated: boolean;
  refreshparent: (x: number) => void;
  setCollapse: () => void;
}
const RightDrawer: React.FC<RightDrawerProps> = ({
  refreshparent,
  events,
  showalerts,
  showhistoryrole,
  showescalated,
  currenttheme,
  showbulk,
  setShowBulk,
  alerts,
  escalatedalerts,
  setSelectedAlert,
  alert,
  loadingright,
  showhistory,
  selectedalerts,
  setSelectedAlerts,
  setAlertsForSite,
  setCollapse,
  appStore,
}: RightDrawerProps) => {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(0);
  const [newSort, setNewSort] = React.useState(false);

  const alertRef = React.useRef(alert);
  const alertsRef = React.useRef(alerts);
  const escalatedalertsRef = React.useRef(escalatedalerts);
  const [lockedSiteId, setLockedId] = React.useState<number>(-1);

  React.useEffect(() => {
    alertRef.current = alert;
  }, [alert]);
  React.useEffect(() => {
    alertsRef.current = alerts;
  }, [alerts]);

  const handleSelectedAlertChange = (alert_id: string) => {
    if (selectedalerts.indexOf(alert_id) >= 0) {
      delete selectedalerts[selectedalerts.indexOf(alert_id)];
    } else {
      selectedalerts.push(alert_id);
    }
    setSelectedAlerts(selectedalerts);
    refreshparent(new Date().getTime());
  };
  const handleUnselectAll = () => {
    selectedalerts = [];
    setSelectedAlerts(selectedalerts);
    refreshparent(new Date().getTime());
  };
  const handleSelectAll = () => {
    selectedalerts = [];
    alerts.forEach((alert) => {
      selectedalerts.push(alert.alert_id);
    });
    setSelectedAlerts(selectedalerts);
    refreshparent(new Date().getTime());
  };

  const handleAlertChange = (selectedalert: Alert) => {
    console.log(
      "handleAlertChange al = ",
      selectedalert?.alert_id,
      "eval ",
      selectedalert?.alert_id === alert?.alert_id,
    );
    if (selectedalert?.alert_id === alert?.alert_id) {
    } else {
      setSelectedAlert(selectedalert);
      setShowBulk(false);
      setRefresh(new Date().getTime());
    }
  };
  const handleBulk = () => {
    setSelectedAlert(undefined);
    selectedalerts = [];
    setSelectedAlerts(selectedalerts);
    setShowBulk(!showbulk);
  };

  let channel_roles = appStore?.authService.getChannelRoles();
  let roles: string[] = [];

  if (channel_roles && Array.isArray(channel_roles)) {
    roles = channel_roles;
  }

  var bulkActionsRole = false;
  if (roles.includes("web_bulk_actions")) {
    bulkActionsRole = true;
  }

  const getIcon = (alert: Alert, training: boolean) => {
    let icon: React.ReactNode[] = [];
    if (training) {
      icon.push(
        <AssistantPhotoIcon
          className={
            currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
          }
        ></AssistantPhotoIcon>,
      );
    }
    if (
      alert.alert_type == "analytics" &&
      alert.class_label &&
      alert?.class_label.includes("safety.")
    ) {
      icon.push(
        <SecurityIcon
          className={
            currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
          }
        ></SecurityIcon>,
      );
    } else if (
      alert.alert_type == "analytics" &&
      alert.class_label &&
      alert?.class_label.includes("human1.")
    ) {
      if (alert?.class_label.includes("human1.vehicles")) {
        icon.push(
          <CarIcon
            style={{ paddingTop: 10, marginLeft: 0 }}
            className={
              currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
            }
          ></CarIcon>,
        );
      } else if (alert?.class_label.includes("human1.people")) {
        icon.push(
          <PeopleIcon
            style={{ paddingTop: 10, marginLeft: 5 }}
            className={
              currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
            }
          ></PeopleIcon>,
        );
      } else {
        icon.push(
          <WavesIcon
            className={
              currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
            }
          ></WavesIcon>,
        );
      }
    } else if (
      alert.alert_type == "analytics" &&
      alert.class_label &&
      alert.class_label?.includes("weapons.")
    ) {
      icon.push(
        <SportsIcon
          className={
            currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
          }
        ></SportsIcon>,
      );
    } else if (alert.alert_type == "object") {
      if (alert.class_label && alert.class_label?.includes("animal.")) {
        icon.push(
          <Pets
            className={
              currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
            }
          ></Pets>,
        );
      } else if (alert.class_label && alert.class_label?.includes("people.")) {
        icon.push(
          <PeopleIcon
            style={{ paddingTop: 8, marginLeft: 5 }}
            className={
              currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
            }
          ></PeopleIcon>,
        );
      } else if (alert.class_label && alert.class_label?.includes("vehicle.")) {
        icon.push(
          <CarIcon
            style={{ paddingTop: 10, marginLeft: 0 }}
            className={
              currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
            }
          ></CarIcon>,
        );
      }
    } else if (alert.alert_type == "snapshot") {
      icon.push(
        <AccessTimeIcon
          className={
            currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
          }
        ></AccessTimeIcon>,
      );
    } else if (
      alert.alert_type == "hub_disconnect" ||
      alert.alert_type == "hub_disconnect_reminder" ||
      alert.alert_type == "sensor_analytics_disconnect" ||
      alert.alert_type == "sensor_analytics_disconnect_reminder" ||
      alert.alert_type == "sensor_analytics_reconnect" ||
      alert.alert_type == "sensor_disconnect" ||
      alert.alert_type == "sensor_disconnect_reminder"
    ) {
      icon.push(
        <CloseIcon
          className={
            currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
          }
        ></CloseIcon>,
      );
    } else if (
      alert.alert_type == "hub_reconnect" ||
      alert.alert_type == "sensor_reconnect"
    ) {
      icon.push(
        <CheckIcon
          className={
            currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
          }
        ></CheckIcon>,
      );
    } else if (alert.alert_type == "motion") {
      icon.push(
        <WavesIcon
          className={
            currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
          }
        ></WavesIcon>,
      );
    }
    if (icon.length === 0) {
      icon.push(
        <Help
          className={
            currenttheme === "dark" ? classes.icon_blue : classes.icon_blue
          }
        ></Help>,
      );
    }
    return icon;
  };
  const getMessage = (alert: Alert) => {
    var allevents = events.filter((x) => x.alert_id === alert.alert_id);
    let message = "";
    var event: { [x: string]: any } | undefined = undefined;
    if (allevents.length > 0) {
      event = allevents[0];
      allevents.forEach((ev) => {
        if (ev["time"] > event!["time"]) {
          event = ev;
        }
      });
    }
    if (event) {
      if (event["type"] === "review") {
        message = "Being reviewed by " + event["username"];
      }
      if (event["type"] === "update") {
        message = "Set to " + event["status"] + " by " + event["username"];
      }
    }
    return message;
  };

  const getEscalatedMessage = (_alert: Alert) => {
    var allevents = events.filter((x) => x.alert_id === _alert.alert_id);
    let message = "";
    var event: { [x: string]: any } | undefined = undefined;
    if (allevents.length > 0) {
      event = allevents[0];
      allevents.forEach((ev) => {
        if (ev["time"] > event!["time"]) {
          event = ev;
        }
      });
    }
    if (event) {
      if (event["type"] === "review") {
        message = "Being reviewed by " + event["username"];
      }
      if (event["type"] === "update" && _alert.review_status === "resolved") {
        message = "Set to " + event["status"] + " by " + event["username"];
      }
    }
    return message;
  };

  const getStyle = (_alert: Alert) => {
    let cl = classes.alert_card_unreviewed;
    if (_alert.review_status === "done") {
      cl = classes.alert_card_done;
    }
    if (_alert.review_status === "resolved") {
      cl = classes.alert_card_resolved;
    }
    if (_alert.review_status === "escalated") {
      cl = classes.alert_card_escalated;
    }
    if (_alert && _alert.alert_id === alert?.alert_id) {
      cl = classes.alert_card_selected;
    }
    var allevents = events.filter((x) => x.alert_id === _alert.alert_id);
    let message = "";
    var event: { [x: string]: any } | undefined = undefined;
    if (allevents.length > 0) {
      event = allevents[0];
      allevents.forEach((ev) => {
        if (ev["time"] > event!["time"]) {
          event = ev;
        }
      });
    }

    if (event) {
      if (event["type"] === "review") {
        cl = classes.review_by_other;
      }
      if (event["type"] === "update") {
        cl = classes.updated_by_other;
      }
    }
    return cl;
  };
  const getEscalatedStyle = (_alert: Alert) => {
    let cl = classes.alert_card_unreviewed;
    if (_alert.review_status === "done") {
      cl = classes.alert_card_done;
    }
    if (_alert.review_status === "resolved") {
      cl = classes.alert_card_resolved;
    }
    if (_alert.review_status === "escalated") {
      cl = classes.alert_card_escalated;
    }
    if (_alert && _alert.alert_id === alert?.alert_id) {
      cl = classes.alert_card_selected_red;
    }
    var allevents = events.filter((x) => x.alert_id === _alert.alert_id);
    var event: { [x: string]: any } | undefined = undefined;
    if (allevents.length > 0) {
      event = allevents[0];
      allevents.forEach((ev) => {
        if (ev["time"] > event!["time"]) {
          event = ev;
        }
      });
    }

    if (event) {
      if (event["type"] === "review") {
        cl = classes.review_by_other;
      }
      if (event["type"] === "update" && _alert.review_status === "resolved") {
        cl = classes.updated_by_other;
      }
    }
    return cl;
  };

  var uniqueSiteIds: any[] = [];
  var uniqueSites: any[] = [];

  function extractDistinctSites(alerts: any) {
    // Use a Set to store unique site IDs
    let siteIds = new Set();

    // Loop through each alert object in the array
    alerts.forEach((alert: any) => {
      // Extract the site_id from each alert and add it to the Set
      if (uniqueSiteIds.indexOf(alert?.site_id) === -1) {
        uniqueSites.push({
          site_name: alert?.site_name,
          site_id: alert?.site_id, // Assigning a unique numeric ID (you can use site_id if it's unique)
        });
        uniqueSiteIds.push(alert?.site_id);
      }
    });

    // Convert the Set back to an array and return it
    return Array.from(siteIds);
  }

  alerts.forEach((alert: any) => {
    // Extract the site_id from each alert and add it to the Set
    if (uniqueSiteIds.indexOf(alert?.site_id) === -1) {
      uniqueSites.push({
        site_name: alert?.site_name,
        site_id: alert?.site_id, // Assigning a unique numeric ID (you can use site_id if it's unique)
      });
      uniqueSiteIds.push(alert?.site_id);
    }
  });

  console.log("UniqueSites: ", uniqueSites);

  var unrevieweds: React.ReactNode[] = [];
  var escalateds: React.ReactNode[] = [];
  var alertcards: React.ReactNode[] = [];
  var escalatedcards: React.ReactNode[] = [];
  var alertcounts = 0;
  var escalatedcounts = 0;

  // if (alerts && !loadingright) {
  //   console.log("Alert List: ", alerts)
  //   let thisdate = "";
  //   //building the alert stack
  //   alerts.forEach((alertel) => {
  //     alertcount++;
  //     let cl = getStyle(alertel);
  //     var message = getMessage(alertel);
  //     var icon = getIcon(alertel, alertel.improve_accuracy_flag);
  //     var camera = alertel.message
  //       .substring(0, alertel.message.indexOf(":"))
  //       .trim();
  //     var detection = alertel.message
  //       .substring(
  //         alertel.message.indexOf(":") + 1,
  //         alertel.message.indexOf(" at "),
  //       )
  //       .trim();
  //     var time = alertel.message
  //       .substring(
  //         alertel.message.lastIndexOf(" at ") + 4,
  //         alertel.message.lastIndexOf(" on "),
  //       )
  //       .trim();

  //     // var date = alertel.message
  //     //   .substring(alertel.message.lastIndexOf(" on ") + 4)
  //     //   .trim();

  //     var fullDate;
  //     if (
  //       new Date(alertel.created * 1000).toLocaleDateString("en-US", {
  //         timeZone: alertel.alert_channel_timezone_str,
  //       })
  //     ) {
  //       var d = new Date(alertel.created * 1000);
  //       var day = d.getDate();
  //       var month = d.getMonth() + 1;
  //       var year = d.getFullYear();
  //       fullDate = d ? `${year}/${month}/${day}` : null;
  //     }

  //     var date =
  //       fullDate ||
  //       alertel.message
  //         .substring(alertel.message.lastIndexOf(" on ") + 4)
  //         .trim();

  //     let selected = false;
  //     if (selectedalerts.indexOf(alertel.alert_id) >= 0) {
  //       selected = true;
  //     }
  //     if (showhistory) {
  //       if (thisdate != date) {
  //         thisdate = date;
  //         alertcards.push(
  //           <Typography
  //             className={
  //               currenttheme == "dark"
  //                 ? classes.bold_text_white
  //                 : classes.bold_text
  //             }
  //             gutterBottom
  //           >
  //             {date}
  //           </Typography>,
  //         );
  //       }
  //       let checkbox = [];
  //       if (showbulk) {
  //         checkbox.push(
  //           <Checkbox
  //             className={classes.checkright}
  //             icon={
  //               <CheckBoxOutlineBlankIcon
  //                 fontSize="small"
  //                 className={
  //                   currenttheme == "dark"
  //                     ? classes.icon_blue
  //                     : classes.icon_blue
  //                 }
  //               />
  //             }
  //             checkedIcon={
  //               <CheckBoxIcon
  //                 fontSize="small"
  //                 className={
  //                   currenttheme == "dark"
  //                     ? classes.icon_blue
  //                     : classes.icon_blue
  //                 }
  //               />
  //             }
  //             checked={selected}
  //             onChange={() => {
  //               setSelectedAlert(alertel); // added
  //               handleSelectedAlertChange(alertel.alert_id);
  //             }}
  //           />,
  //         );
  //       }
  //       alertcards.push(
  //         <Card className={cl}>
  //           <Grid
  //             container
  //             spacing={2}
  //             onClick={() => (!showbulk ? handleAlertChange(alertel) : null)}
  //           >
  //             <Grid item>{icon}</Grid>
  //             <Grid item xs={12} sm container>
  //               <Grid item xs container direction="column" spacing={2}>
  //                 <Grid item xs>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                   >
  //                     <span>{message}</span>
  //                   </Typography>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                   >
  //                     <span>{camera}</span>
  //                     {checkbox}
  //                   </Typography>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                     gutterBottom
  //                   // onClick={() => handleAlertChange(alertel)}
  //                   >
  //                     {detection + " : " + time}
  //                   </Typography>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Card>,
  //       );
  //     } else {
  //       if (thisdate != date) {
  //         thisdate = date;
  //         alertcards.push(
  //           <Typography
  //             className={
  //               currenttheme == "dark"
  //                 ? classes.bold_text_white
  //                 : classes.bold_text
  //             }
  //             gutterBottom
  //           >
  //             {date}
  //           </Typography>,
  //         );
  //       }
  //       let checkbox = [];
  //       if (showbulk) {
  //         checkbox.push(
  //           <Checkbox
  //             className={classes.checkright}
  //             icon={
  //               <CheckBoxOutlineBlankIcon
  //                 fontSize="small"
  //                 className={
  //                   currenttheme == "dark"
  //                     ? classes.icon_blue
  //                     : classes.icon_blue
  //                 }
  //               />
  //             }
  //             checkedIcon={
  //               <CheckBoxIcon
  //                 fontSize="small"
  //                 className={
  //                   currenttheme == "dark"
  //                     ? classes.icon_blue
  //                     : classes.icon_blue
  //                 }
  //               />
  //             }
  //             checked={selected}
  //             onChange={() => {
  //               setSelectedAlert(alertel); // added
  //               handleSelectedAlertChange(alertel.alert_id);
  //             }}
  //           />,
  //         );
  //       }
  //       alertcards.push(
  //         <Card className={cl}>
  //           <Grid
  //             container
  //             spacing={2}
  //             onClick={() => (!showbulk ? handleAlertChange(alertel) : null)}
  //           >
  //             <Grid item>{icon}</Grid>
  //             <Grid item xs={12} sm container>
  //               <Grid item xs container direction="column" spacing={2}>
  //                 <Grid item xs>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                   >
  //                     <span>{message}</span>
  //                   </Typography>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                   >
  //                     <span>{camera}</span>
  //                     {checkbox}
  //                   </Typography>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                     gutterBottom
  //                   // onClick={() => handleAlertChange(alertel)}
  //                   >
  //                     {detection + " : " + time}
  //                   </Typography>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Card>,
  //       );
  //     }
  //   });
  //   thisdate = "";
  //   escalatedalerts.forEach((alertel) => {
  //     escalatedcount++;
  //     let cl = getEscalatedStyle(alertel);
  //     var message = getEscalatedMessage(alertel);
  //     var icon = getIcon(alertel, alertel.improve_accuracy_flag);
  //     var camera = alertel.message
  //       .substring(0, alertel.message.indexOf(":"))
  //       .trim();
  //     var detection = alertel.message
  //       .substring(
  //         alertel.message.indexOf(":") + 1,
  //         alertel.message.indexOf(" at "),
  //       )
  //       .trim();
  //     var time = alertel.message
  //       .substring(
  //         alertel.message.lastIndexOf(" at ") + 4,
  //         alertel.message.lastIndexOf(" on "),
  //       )
  //       .trim();
  //     // var date = alertel.message
  //     //   .substring(alertel.message.lastIndexOf(" on ") + 4)
  //     //   .trim();
  //     var date =
  //       new Date(alertel.created * 1000).toLocaleDateString("en-US", {
  //         timeZone: alertel.alert_channel_timezone_str,
  //       }) ||
  //       alertel.message
  //         .substring(alertel.message.lastIndexOf(" on ") + 4)
  //         .trim();

  //     if (!showhistory) {
  //       if (thisdate != date) {
  //         thisdate = date;
  //         escalatedcards.push(
  //           <Typography
  //             className={
  //               currenttheme == "dark"
  //                 ? classes.bold_text_white
  //                 : classes.bold_text
  //             }
  //             gutterBottom
  //           >
  //             &nbsp;{date}
  //           </Typography>,
  //         );
  //       }

  //       escalatedcards.push(
  //         <Card className={cl}>
  //           <Grid
  //             container
  //             spacing={2}
  //             onClick={() => handleAlertChange(alertel)}
  //           >
  //             <Grid item>{icon}</Grid>
  //             <Grid item xs={12} sm container>
  //               <Grid item xs container direction="column" spacing={2}>
  //                 <Grid item xs>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                   >
  //                     <span>{message}</span>
  //                   </Typography>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                   >
  //                     <span>{camera}</span>
  //                   </Typography>
  //                   <Typography
  //                     className={
  //                       currenttheme == "dark"
  //                         ? classes.standard_text
  //                         : classes.standard_text
  //                     }
  //                     gutterBottom
  //                   >
  //                     {detection + " : " + time}
  //                   </Typography>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Card>,
  //       );
  //     }
  //   });
  // } else {
  //   alertcards.push(
  //     <div className="wait-container">
  //       <svg width="48px" height="48px" viewBox="0 0 128 128">
  //         <g>
  //           <linearGradient id="linear-gradient">
  //             <stop offset="0%" stopColor="#ffffff" />
  //             <stop offset="100%" stopColor="#22305f" />
  //           </linearGradient>
  //           <path
  //             d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
  //             fill="url(#linear-gradient)"
  //             fill-rule="evenodd"
  //           />
  //           <animateTransform
  //             attributeName="transform"
  //             type="rotate"
  //             from="0 64 64"
  //             to="360 64 64"
  //             dur="1080ms"
  //             repeatCount="indefinite"
  //           ></animateTransform>
  //         </g>
  //       </svg>
  //     </div>,
  //   );
  // }

  const alertsForSite = (siteId: number) => {
    let unreviewed: React.ReactNode[] = [];
    let escalated: React.ReactNode[] = [];
    let alertcards: React.ReactNode[] = [];
    let escalatedcards: React.ReactNode[] = [];
    let alertsOnSite: any[] = [];
    let escalatedAlertsOnSite: any[] = [];
    let alertcount = 0;
    let escalatedcount = 0;
    let firstAlert: any;
    let firstEscalatedAlert: any;
    let lastAlert: any;
    let lastEscalatedAlert: any;
    if (alerts && !loadingright) {
      console.log("Alert List: ", alerts);
      let thisdate = "";
      //building the alert stack
      alertsOnSite = alerts.filter((al) => al.site_id === siteId);
      escalatedAlertsOnSite = escalatedalerts.filter(
        (al) => al.site_id === siteId,
      );
      alertsOnSite.forEach((alertel, inddex: number) => {
        alertcount++;
        if (inddex === 0) {
          firstAlert = alertel;
        } else if (inddex === alertsOnSite.length - 1) {
          lastAlert = alertel;
        }
        // if (newSort && inddex === 0) {
        //   firstAlert = alertel;
        // } else if (!newSort && inddex === alertsOnSite.length - 1) {
        //   lastAlert = alertel;
        // }
        let cl = getStyle(alertel);
        var message = getMessage(alertel);
        var icon = getIcon(alertel, alertel.improve_accuracy_flag);
        var camera = alertel.message
          .substring(0, alertel.message.indexOf(":"))
          .trim();
        var detection = alertel.message
          .substring(
            alertel.message.indexOf(":") + 1,
            alertel.message.indexOf(" at "),
          )
          .trim();
        var time = alertel.message
          .substring(
            alertel.message.lastIndexOf(" at ") + 4,
            alertel.message.lastIndexOf(" on "),
          )
          .trim();

        // var date = alertel.message
        //   .substring(alertel.message.lastIndexOf(" on ") + 4)
        //   .trim();

        var fullDate;
        if (
          new Date(alertel.created * 1000).toLocaleDateString("en-US", {
            timeZone: alertel.alert_channel_timezone_str,
          })
        ) {
          var d = new Date(alertel.created * 1000);
          var day = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
          fullDate = d ? `${year}/${month}/${day}` : null;
        }

        var date =
          fullDate ||
          alertel.message
            .substring(alertel.message.lastIndexOf(" on ") + 4)
            .trim();

        let selected = false;
        if (selectedalerts.indexOf(alertel.alert_id) >= 0) {
          selected = true;
        }
        if (showhistory) {
          if (thisdate != date) {
            thisdate = date;
            alertcards.push(
              <Typography
                className={
                  currenttheme == "dark"
                    ? classes.bold_text_white
                    : classes.bold_text
                }
                gutterBottom
              >
                {date}
              </Typography>,
            );
          }
          let checkbox = [];
          if (showbulk) {
            checkbox.push(
              <Checkbox
                className={classes.checkright}
                icon={
                  <CheckBoxOutlineBlankIcon
                    fontSize="small"
                    className={
                      currenttheme == "dark"
                        ? classes.icon_blue
                        : classes.icon_blue
                    }
                  />
                }
                checkedIcon={
                  <CheckBoxIcon
                    fontSize="small"
                    className={
                      currenttheme == "dark"
                        ? classes.icon_blue
                        : classes.icon_blue
                    }
                  />
                }
                checked={selected}
                onChange={() => {
                  setSelectedAlert(alertel); // added
                  handleSelectedAlertChange(alertel.alert_id);
                }}
              />,
            );
          }
          alertcards.push(
            <Card className={cl}>
              <Grid
                container
                spacing={2}
                onClick={() => (!showbulk ? handleAlertChange(alertel) : null)}
              >
                <Grid item>{icon}</Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        className={
                          currenttheme == "dark"
                            ? classes.standard_text
                            : classes.standard_text
                        }
                      >
                        <span>{message}</span>
                      </Typography>
                      <Typography
                        className={
                          currenttheme == "dark"
                            ? classes.standard_text
                            : classes.standard_text
                        }
                      >
                        <span>{camera}</span>
                        {checkbox}
                      </Typography>
                      <Typography
                        className={
                          currenttheme == "dark"
                            ? classes.standard_text
                            : classes.standard_text
                        }
                        gutterBottom
                        // onClick={() => handleAlertChange(alertel)}
                      >
                        {detection + " : " + time}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>,
          );
        } else {
          if (thisdate != date) {
            thisdate = date;
            alertcards.push(
              <Typography
                className={
                  currenttheme == "dark"
                    ? classes.bold_text_white
                    : classes.bold_text
                }
                gutterBottom
              >
                {date}
              </Typography>,
            );
          }
          let checkbox = [];
          if (showbulk) {
            checkbox.push(
              <Checkbox
                className={classes.checkright}
                icon={
                  <CheckBoxOutlineBlankIcon
                    fontSize="small"
                    className={
                      currenttheme == "dark"
                        ? classes.icon_blue
                        : classes.icon_blue
                    }
                  />
                }
                checkedIcon={
                  <CheckBoxIcon
                    fontSize="small"
                    className={
                      currenttheme == "dark"
                        ? classes.icon_blue
                        : classes.icon_blue
                    }
                  />
                }
                checked={selected}
                onChange={() => {
                  setSelectedAlert(alertel); // added
                  handleSelectedAlertChange(alertel.alert_id);
                }}
              />,
            );
          }
          alertcards.push(
            <Card className={cl}>
              <Grid
                container
                spacing={2}
                onClick={() => (!showbulk ? handleAlertChange(alertel) : null)}
              >
                <Grid item>{icon}</Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        className={
                          currenttheme == "dark"
                            ? classes.standard_text
                            : classes.standard_text
                        }
                      >
                        <span>{message}</span>
                      </Typography>
                      <Typography
                        className={
                          currenttheme == "dark"
                            ? classes.standard_text
                            : classes.standard_text
                        }
                      >
                        <span>{camera}</span>
                        {checkbox}
                      </Typography>
                      <Typography
                        className={
                          currenttheme == "dark"
                            ? classes.standard_text
                            : classes.standard_text
                        }
                        gutterBottom
                        // onClick={() => handleAlertChange(alertel)}
                      >
                        {detection + " : " + time}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>,
          );
        }
      });
      thisdate = "";
      escalatedalerts
        .filter((al) => al.site_id === siteId)
        .forEach((alertel, indexx: number) => {
          escalatedcount++;

          if (indexx === 0) {
            firstEscalatedAlert = alertel;
          } else if (indexx === escalatedalerts.length - 1) {
            lastEscalatedAlert = alertel;
          }

          // if (indexx === 0) {
          //   firstEscalatedAlert = alertel;
          // } else if (!newSort && indexx === escalatedalerts.length - 1) {
          //   lastEscalatedAlert = alertel;
          // }
          let cl = getEscalatedStyle(alertel);
          var message = getEscalatedMessage(alertel);
          var icon = getIcon(alertel, alertel.improve_accuracy_flag);
          var camera = alertel.message
            .substring(0, alertel.message.indexOf(":"))
            .trim();
          var detection = alertel.message
            .substring(
              alertel.message.indexOf(":") + 1,
              alertel.message.indexOf(" at "),
            )
            .trim();
          var time = alertel.message
            .substring(
              alertel.message.lastIndexOf(" at ") + 4,
              alertel.message.lastIndexOf(" on "),
            )
            .trim();
          // var date = alertel.message
          //   .substring(alertel.message.lastIndexOf(" on ") + 4)
          //   .trim();
          var date =
            new Date(alertel.created * 1000).toLocaleDateString("en-US", {
              timeZone: alertel.alert_channel_timezone_str,
            }) ||
            alertel.message
              .substring(alertel.message.lastIndexOf(" on ") + 4)
              .trim();

          if (!showhistory) {
            if (thisdate != date) {
              thisdate = date;
              escalatedcards.push(
                <Typography
                  className={
                    currenttheme == "dark"
                      ? classes.bold_text_white
                      : classes.bold_text
                  }
                  gutterBottom
                >
                  &nbsp;{date}
                </Typography>,
              );
            }

            escalatedcards.push(
              <Card className={cl}>
                <Grid
                  container
                  spacing={2}
                  onClick={() => handleAlertChange(alertel)}
                >
                  <Grid item>{icon}</Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography
                          className={
                            currenttheme == "dark"
                              ? classes.standard_text
                              : classes.standard_text
                          }
                        >
                          <span>{message}</span>
                        </Typography>
                        <Typography
                          className={
                            currenttheme == "dark"
                              ? classes.standard_text
                              : classes.standard_text
                          }
                        >
                          <span>{camera}</span>
                        </Typography>
                        <Typography
                          className={
                            currenttheme == "dark"
                              ? classes.standard_text
                              : classes.standard_text
                          }
                          gutterBottom
                        >
                          {detection + " : " + time}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>,
            );
          }
        });
    } else {
      alertcards.push(
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stopColor="#ffffff" />
                <stop offset="100%" stopColor="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>,
      );
    }

    return {
      unreviewed,
      escalated,
      alertcards,
      escalatedcards,
      alertcount,
      escalatedcount,
      alertsOnSite,
      escalatedAlertsOnSite,
      firstEscalatedAlert: firstAlert,
      firstAlert: firstAlert,
      selectFirstAlert: () => handleAlertChange(firstAlert),
      selectFirstEscalatedAlert: () => handleAlertChange(firstEscalatedAlert),
      selectLastAlert: () => handleAlertChange(lastAlert),
      selectLastEscalatedAlert: () => handleAlertChange(lastEscalatedAlert),
    };
  };

  let alertsData = uniqueSites.map((site) => {
    const res = alertsForSite(site.site_id);
    return { ...res, ...site };
  });

  // console.log('DATA SITES: ', alertsData, "LOCKED SITE ID: ", lockedSiteId)

  // if(lockedSiteId > -1){
  //   const foundSite = alertsData.find((item: any) => item.site_id === lockedSiteId)
  //   if(foundSite){
  //     const foundIndex = alertsData.findIndex((item: any) => item.site_id === lockedSiteId)
  //     if(foundIndex > -1){
  //       console.log("alertsDataa before", alertsData, 'foundIndex: ', foundIndex, 'lockedSiteId: ', lockedSiteId)
  //       alertsData = alertsData.filter((item: any) => item.site_id !== lockedSiteId)
  //       console.log("alertsDataa spliced", alertsData, 'foundIndex: ', foundIndex, 'lockedSiteId: ', lockedSiteId)
  //       alertsData.splice(0, 0, foundSite);
  //     }
  //   }
  // }

  console.log(
    "DATA SITES: after ",
    alertsData,
    "LOCKED SITE ID: ",
    lockedSiteId,
  );

  alertsData.map((item: any) => {
    escalatedcounts += item.escalatedcount;
    alertcounts += item.alertcount;
  });

  //show fullscreen if no escalated alert stack
  var fullscreen = false;
  if (showhistory) {
    fullscreen = true;
  } else {
    if (showalerts != showescalated) {
      fullscreen = true;
    }
  }

  const scrollStyles = makeStyles({
    paper: {
      width: "100%",
      height: window.innerHeight * (fullscreen ? 1 : 0.5),
      overflowY: "auto",
      background: "#FFFFFF",
      // boxShadow:
      //   "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
    },
    paper_dark: {
      width: "100%",
      height: window.innerHeight * (fullscreen ? 1 : 0.5),
      overflowY: "auto",
      background: "#374C82",
      // "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
      // boxShadow:
      //   "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
    },
    paper_dark_red: {
      width: "100%",
      height: window.innerHeight * (fullscreen ? 1 : 0.5),
      overflowY: "auto",
      background: "#6C0F02",
      // "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
      // boxShadow:
      //   "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
    },
  });
  const scrollclasses = scrollStyles();
  var bulkicons: React.ReactNode[] = [];
  if (showbulk) {
    bulkicons.push(
      <Tooltip title="Select all" aria-label="add">
        <div className={classes.pointer} onClick={handleSelectAll}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.53 11.06L15.47 10l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 17l5.94-5.94z"
              fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
              fillOpacity="0.9"
            />
            <path
              d="M19 4H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H5V6h14v12z"
              fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
              fillOpacity="0.9"
            />
          </svg>
        </div>
      </Tooltip>,
    );
    bulkicons.push(
      <Tooltip title="Unselect all" aria-label="add">
        <div className={classes.pointer} onClick={handleUnselectAll}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 4H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H5V6h14v12z"
              fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
              fillOpacity="0.9"
            />
          </svg>
        </div>
      </Tooltip>,
    );
  }

  unrevieweds.push(
    <>
      <div className={classes.space_smallest}></div>
      <br />
      <div className={classes.shelf_item}>
        <span
          className={
            currenttheme == "dark" ? classes.bold_text_white : classes.bold_text
          }
          style={{ fontSize: 12, fontWeight: 700, marginBottom: 10 }}
        >
          Alarm/Event Stack: ({alertcounts})
        </span>
        {bulkActionsRole ? (
          <Tooltip title="Bulk select mode" aria-label="add">
            <div className={classes.pointer} onClick={handleBulk}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 8H6v7c0 1.1.9 2 2 2h9v-2H8V8z"
                  fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
                  fillOpacity="0.9"
                />
                <path
                  d="M20 3h-8c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 8h-8V7h8v4zM4 12H2v7c0 1.1.9 2 2 2h9v-2H4v-7z"
                  fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
                  fillOpacity="0.9"
                />
              </svg>
            </div>
          </Tooltip>
        ) : null}
        {bulkicons}
        {newSort ? (
          <span
            onClick={() => setNewSort(!newSort)}
            style={{ color: "white", fontWeight: 700 }}
          >
            Newest <CaretDownFilled />
          </span>
        ) : (
          <span
            onClick={() => setNewSort(!newSort)}
            style={{ color: "white", fontWeight: 700 }}
          >
            Oldest <CaretUpFilled />
          </span>
        )}
      </div>
    </>,
  );

  function convertToReadableTime(timestamp: number) {
    // Create a new Date object using the timestamp (multiplied by 1000 for milliseconds)
    const date = new Date(timestamp * 1000);

    // Extract hours, minutes, and seconds
    const hours = date.getUTCHours(); // Use UTC to avoid timezone offset
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    // Format the time components with leading zeros if needed
    const formattedTime = `${String(hours).padStart(2, "0")}h${String(
      minutes,
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

    return formattedTime;
  }

  alertsData.map((item) => {
    console.log("firstAlert: ", item);
    unrevieweds.push(
      <>
        <div
          style={{ padding: 1, paddingRight: 0, paddingLeft: 0 }}
          onClick={() => {
            // item.selectFirstAlert()
            setAlertsForSite(item.alertsOnSite);
          }}
        >
          <AlertsForSite
            item={() => {
              if (item.alertcards.length > 0) {
                newSort ? item.selectFirstAlert() : item.selectLastAlert();
                setLockedId(item.site_id);
              }
            }}
            site_name={item.site_name}
            count={item.alertcount}
            time={convertToReadableTime(item.firstAlert.created)}
          >
            <Paper
              className={
                currenttheme == "dark"
                  ? scrollclasses.paper_dark
                  : scrollclasses.paper
              }
            >
              {newSort ? item.alertcards : item.alertcards.reverse()}
            </Paper>
          </AlertsForSite>
        </div>
      </>,
    );
  });

  // unrevieweds.push(
  //   <>
  //     <div className={classes.space_smallest}></div>
  //     <div className={classes.shelf_item}>
  //       <span
  //         className={
  //           currenttheme == "dark" ? classes.bold_text_white : classes.bold_text
  //         }
  //       >
  //         Alarm/Event Stack: ({alertcounts})
  //       </span>
  //       {bulkActionsRole ? (
  //         <Tooltip title="Bulk select mode" aria-label="add">
  //           <div className={classes.pointer} onClick={handleBulk}>
  //             <svg
  //               width="24"
  //               height="24"
  //               viewBox="0 0 24 24"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M8 8H6v7c0 1.1.9 2 2 2h9v-2H8V8z"
  //                 fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
  //                 fillOpacity="0.9"
  //               />
  //               <path
  //                 d="M20 3h-8c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 8h-8V7h8v4zM4 12H2v7c0 1.1.9 2 2 2h9v-2H4v-7z"
  //                 fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
  //                 fillOpacity="0.9"
  //               />
  //             </svg>
  //           </div>
  //         </Tooltip>
  //       ) : null}
  //       {bulkicons}
  //     </div>
  //     <Paper
  //       className={
  //         currenttheme == "dark"
  //           ? scrollclasses.paper_dark
  //           : scrollclasses.paper
  //       }
  //     >
  //       <div style={{ padding: 10, paddingRight: 0, paddingLeft: 0 }}>
  //         <AlertsForSite />
  //       </div>
  //       {alertcards}
  //     </Paper>
  //   </>,
  // );

  if (!showhistory) {
    escalateds.push(
      <>
        <div
          style={{ marginTop: 50, paddingBottom: 20 }}
          className={classes.space_smallest}
        ></div>
        <div className={classes.shelf_item}>
          <span
            className={
              currenttheme === "dark"
                ? classes.bold_text_white
                : classes.bold_text
            }
            style={{ marginBottom: 10 }}
          >
            Escalated Events Stack: ({escalatedcounts})
          </span>
        </div>
        {/* <Paper
          className={
            currenttheme === "dark"
              ? scrollclasses.paper_dark
              : scrollclasses.paper
          }
        >
          {escalatedcards}
        </Paper> */}
      </>,
    );

    // modify start
    alertsData.map((item) => {
      if (item.escalatedcards.length > 0)
        escalateds.push(
          <>
            <div
              style={{ padding: 1, paddingRight: 0, paddingLeft: 0 }}
              onClick={() => {
                setAlertsForSite(item.escalatedAlertsOnSite);
              }}
            >
              <AlertsForSite
                item={() => {
                  if (item.escalatedcards.length > 0) {
                    newSort
                      ? item.selectFirstEscalatedAlert()
                      : item.selectLastEscalatedAlert();
                    setLockedId(item.site_id);
                  }
                }}
                red
                site_name={item.site_name}
                count={item.escalatedcount}
                time={convertToReadableTime(item.firstEscalatedAlert.created)}
              >
                <Paper
                  className={
                    currenttheme == "dark"
                      ? scrollclasses.paper_dark_red
                      : scrollclasses.paper
                  }
                >
                  {newSort
                    ? item.escalatedcards
                    : item.escalatedcards.reverse()}
                </Paper>
              </AlertsForSite>
            </div>
          </>,
        );
    });
    // modify end
  }
  if (loadingright && (showalerts || showescalated)) {
    return (
      <div
        className={
          currenttheme === "dark"
            ? classes.darkrightbackground
            : classes.lightrightbackground
        }
      >
        <div className="wait-container">
          <svg
            className={classes.svg_margin}
            width="48px"
            height="48px"
            viewBox="0 0 128 128"
          >
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stopColor="#ffffff" />
                <stop offset="100%" stopColor="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={
          currenttheme === "dark"
            ? classes.darkrightbackground
            : classes.lightrightbackground
        }
      >
        {showalerts || showhistory ? unrevieweds : <></>}
        {showescalated ? escalateds : <></>}
      </div>
    );
  }
};

export default RightDrawer;
